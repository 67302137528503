/* eslint-disable react-hooks/exhaustive-deps */
import { RightChevron } from "../lib/icons";
import React, { useState } from "react";

export const DashboardRptItem = ({
  workspace,
  idx,
  checkboxChangeHandler,
  selectedDashboardReports
}) => {
  const [collapsed, setCollapsed] = useState(true);

  const isReportSelected = (workspaceId, reportId, workspace) => {
    return selectedDashboardReports.some(ws => {
      if (ws.workspaceId === undefined) {
        return (
          ws._id === workspaceId && ws.reports.some(rpt => rpt._id === reportId)
        );
      } else if (
        typeof ws.workspaceId === "object" &&
        ws.workspaceId !== null
      ) {
        return (
          ws.workspaceId._id === workspaceId &&
          ws.reports.some(rpt => rpt._id === reportId)
        );
      } else {
        if (ws.workspaceId.includes("-")) {
          return (
            ws.workspaceId === workspace.workspaceId &&
            ws.reports.some(rpt => rpt._id === reportId)
          );
        } else {
          return (
            ws.workspaceId === workspaceId &&
            ws.reports.some(rpt => rpt._id === reportId)
          );
        }
      }
    });
  };

  function renderReports() {
    if (!collapsed) {
      return workspace.reports.map((item, rIdx) => {
        return (
          <div
            key={idx + "rpt" + rIdx}
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: "20px",
              alignItems: "center"
            }}
          >
            <input
              style={{ marginRight: "10px" }}
              type="checkbox"
              onChange={e => {
                checkboxChangeHandler(e, item, "dashboardReports");
              }}
              checked={isReportSelected(
                workspace._id,
                item._id || item.id,
                workspace
              )}
            />
            <div>{item.name}</div>
          </div>
        );
      });
    } else {
      return null;
    }
  }

  return (
    <div
      key={"DashboardRpt" + idx}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div
        onClick={() => {
          setCollapsed(!collapsed);
        }}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <RightChevron
          transform={collapsed ? "rotate(270,12,12)" : "-rotate(90,12,12)"}
          height={"20px"}
          color={"rgba(88, 89, 91, 1)"}
          viewBox={"-1 2 24 24"}
        />
        <div key={"iItem" + workspace.id + idx}>{workspace.name}</div>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {renderReports()}
      </div>
    </div>
  );
};
